import React, { useState } from 'react';
import { SurveyQuestions } from '../../types/reducerTypes';
// import translations from '../../utils/language';
// import { printLog } from '../../utils/logging';
interface Props {
  ref: any;
  questions: SurveyQuestions[];
  // validateFor: number;
  givenAnswers: (event: any) => void;
  selectedAnswer?: (event: any) => void;
  noOfAnswersSelected?: (event: any) => void;
}
export interface AnswerData {
  questionId: number;
  answerResponse: string;
  notes?: string;
}
type answers = {
  [key: string]: AnswerData;
};
export const QuestionAnswer: React.FC<Props> = React.forwardRef(
  (props, ref) => {
    const [answers, setAnswers] = useState({} as answers);
    // const [givenAnswers, setGivenAnswers] = useState(0);
    // printLog('validate', Object.keys(answers).length, answers);
    // printLog('props validate', props.validateFor);
    const inputHandler = (queId: string, event: any, queType: string): void => {
      const userAnswer: answers = { ...answers };
      // if (queType === 'notes') {
      //   userAnswer[queId] = {
      //     questionId: Number(queId),
      //     answerResponse: 'notes',
      //     notes: event.target.value,
      //   };
      // } else {
      // }
      userAnswer[queId] = {
        questionId: Number(queId),
        answerResponse: queType === 'notes' ? 'notes' : event.target.value,
        notes: queType === 'notes' ? event.target.value : '',
      };
      setAnswers(userAnswer);
      if (queType !== 'notes') {
        // setGivenAnswers(Object.keys(userAnswer).length);
        if (props.selectedAnswer) {
          props.selectedAnswer(Object.values(userAnswer));
        }
        if (props.noOfAnswersSelected) {
          props.noOfAnswersSelected(Object.keys(userAnswer).length);
        }
      }
    };
    React.useImperativeHandle(ref, () => ({
      submitData() {
        // if (Object.keys(answers).length > 0) {
        // }
        props.givenAnswers(Object.values(answers));
        setAnswers({});
        // setGivenAnswers(0);
      },
    }));
    return (
      <div className="allQueAns">
        {props.questions.map((queAnsObj, index) => {
          const queId = queAnsObj.questionId.toString();
          // printLog('inside que map');

          return (
            <div className="queAnsWrapper" key={index}>
              <div
                data-testid="que-div"
                className={`question gap ${
                  queAnsObj.questionType !== 'notes' ? 'bold' : ''
                }`}
              >
                {queAnsObj.question}
              </div>
              <div className="answer_wrapper">
                {queAnsObj.answers
                  .slice(0)
                  // .reverse()
                  .map((answer, index) => {
                    // printLog('inside answer map');
                    return (
                      <div
                        key={index}
                        className={`single_answer_wrapper ${queAnsObj.questionType}`}
                      >
                        {queAnsObj.questionType === 'notes' ? (
                          <textarea
                            name="reason"
                            className="reason_txt_area"
                            // value={answers[queId]?.answerResponse}
                            maxLength={4000}
                            onChange={(event) =>
                              inputHandler(queId, event, queAnsObj.questionType)
                            }
                          />
                        ) : (
                          <>
                            <input
                              id={`${queId}-${index.toString()}`}
                              type={queAnsObj.questionType}
                              name={queId}
                              value={answer.tagCode}
                              checked={
                                answers[queId]?.answerResponse ===
                                answer.tagCode
                                  ? true
                                  : false
                              }
                              onChange={(event) =>
                                inputHandler(
                                  queId,
                                  event,
                                  queAnsObj.questionType
                                )
                              }
                              // onClick={
                              //     queAnsObj.questionId === 1
                              //       ? props.givenRating
                              //       : props.selectedAnswer
                              //   }
                            />
                            <label htmlFor={`${queId}-${index.toString()}`}>
                              {answer.tagCode}
                            </label>
                          </>
                        )}
                      </div>
                    );
                  })}
                {queAnsObj.questionType === 'radio' && (
                  <div className="answer-hint">
                    <span>{queAnsObj.answers[0].tagValue}</span>
                    <span>
                      {queAnsObj.answers[queAnsObj.answers.length - 1].tagValue}
                    </span>
                  </div>
                )}
              </div>
            </div>
          );
        })}
        {/* <div className="row mlm20 mrm20 bottom_btn_fix">
        <button
          className={`btn black ui-link ${
            props.validateFor > givenAnswers ? 'disabled' : ''
          }`}
          onClick={submitData}
        >
          {translations.SUBMIT}
        </button>
      </div> */}
      </div>
    );
  }
);
export default QuestionAnswer;
