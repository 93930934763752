// utils/API.js
import axios, { AxiosPromise } from 'axios';
import { getStore } from '../store/store';
import { AUTH_EXPIRE } from '../saga/config/configActionTypes';
// const axiosInstance = axios.create({
//   baseURL: environment,
//   responseType: "json"
// });

const API = (
  methodType: any,
  endpoint: string,
  payload?: any,
  authRequire?: boolean,
  responseType?: any
): AxiosPromise => {
  const headers: any = {
    'Content-Type': 'application/json',
  };
  if (authRequire) {
    const store = getStore().getState();
    headers[
      'Authorization'
    ] = `Bearer ${store.authentication?.internalAccessToken}`;
  }

  return axios({
    method: methodType,
    url: endpoint,
    responseType: responseType,
    data: payload,
    headers,
  });
};

axios.interceptors.request.use((request) => {
  // const user = JSON.parse(localStorage.getItem('user'));
  // if (user && user.token) {
  //   // Modify request here
  //   request.headers['x-access-token'] = user.token;
  // }
  return request;
});
axios.interceptors.response.use(
  (response) => {
    // const user = JSON.parse(localStorage.getItem('user'));
    // if (user && user.token) {
    //   // Modify request here
    //   request.headers['x-access-token'] = user.token;
    // }
    return response;
  },
  (error) => {
    const store = getStore();
    if (error.response.status === 401) {
      store.dispatch({ type: AUTH_EXPIRE, response: error.response });
    }
    throw error;
  }
);
export default API;
