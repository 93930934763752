import React from 'react';
import './survey.scss';
import Header from '../Header';
import {
  SurveyInitialStateTypes,
  SurveyQuestions,
} from '../../types/reducerTypes';
import QuestionAnswer from './questionAnswer';
// import { printLog } from '../../utils/logging';
import translations from '../../utils/language';
import { showAlertModal } from '../../utils/AlertModal';
import { TIME_EXPIRED_IMG } from '../../utils/images';
// import { partnerCode } from '../../constants/utilConstants';
interface SurveyPostParam {
  questionId: number;
  answerResponse: string;
  notes: string;
}
interface Props {
  sendSurvey: (param: SurveyPostParam[]) => void;
  backgroundSubmit: (param: SurveyPostParam[]) => void;
  partnerCode: string;
  error: any;
  errorData: any;
  errorCode: number;
}
type FullProps = SurveyInitialStateTypes & Props;
interface QuePattern {
  queStartIndex: number;
  queGroupIndex: number;
}
interface State {
  headerTitle: string;
  headerDesc: string;
  visibleQuestions: SurveyQuestions[];
  surveyAnswers: [];
  quePattern: QuePattern;
  validateFor: number;
  error: boolean;
  isSubmitted: boolean;
  submitEnable: boolean;
  overflowActive: boolean;
  currentScreenAnswers: number;
}

class SurveyScreen extends React.Component<FullProps, State> {
  queGroup: number[];
  queComponentRef: any;
  surveyScreenRef: any;
  constructor(props: FullProps) {
    super(props);
    this.state = {
      headerTitle: '',
      headerDesc: '',
      visibleQuestions: [],
      surveyAnswers: [],
      quePattern: {
        queStartIndex: 0,
        queGroupIndex: 0,
      },
      validateFor: 0,
      isSubmitted: false,
      error: false,
      submitEnable: false,
      overflowActive: false,
      currentScreenAnswers: 0,
    };
    this.queComponentRef = React.createRef();
    this.surveyScreenRef = React.createRef();
    this.queGroup = [1, 1, 1, 1, 2, 1, 1];
    if (this.props.partnerCode === 'lm') {
      this.queGroup = [2, 1, 2];
    }
  }

  static getDerivedStateFromProps(props: FullProps): any {
    if (props.success || props.error) {
      if (props.error && props.errorCode !== 400) {
        showAlertModal(
          translations.OOPS_API_ERROR,
          translations.OOPS,
          translations.OK
        );
        return null;
      } else {
        return {
          visibleQuestions: [],
          // submitEnable: false,
        };
      }
    }
    return null;
  }
  componentDidMount(): void {
    let objSet: any = {};
    const response = this.processVisibleQuestion();
    if (response) {
      objSet = { ...objSet, ...response };
    }
    if (Object.keys(objSet).length) {
      this.setState(objSet);
    }
  }
  processVisibleQuestion = (): any => {
    const queAns = [];
    let validateFor = 0;
    if (this.props.questions.length > this.state.quePattern.queStartIndex) {
      const questionEndIndex =
        this.state.quePattern.queStartIndex +
        this.queGroup[this.state.quePattern.queGroupIndex];
      for (
        let index = this.state.quePattern.queStartIndex;
        index < questionEndIndex;
        index++
      ) {
        queAns.push(this.props.questions[index]);
        if (this.props.questions[index].questionType !== 'notes') {
          validateFor += 1;
          // setValidateFor(validateFor + 1);
          // validateFor += validateFor;
          // printLog('validateFor', validateFor);
        }
      }
      return {
        visibleQuestions: queAns,
        quePattern: {
          queStartIndex: questionEndIndex,
          queGroupIndex: this.state.quePattern.queGroupIndex + 1,
        },
        validateFor: validateFor,
        submitEnable: false,
      };
    } else {
      return false;
    }
  };
  receivedAnswers = (answer: any): void => {
    let obj: any = {
      surveyAnswers: [...this.state.surveyAnswers, ...answer],
    };
    // this.setState(obj);
    const response = this.processVisibleQuestion();
    if (response) {
      obj.isSubmitted = true;
      obj = { ...obj, ...response };
      this.setState(obj);
    } else {
      // obj.submitEnable = false;
      this.setState(obj, () => this.props.sendSurvey(this.state.surveyAnswers));
    }
  };
  componentDidUpdate(prevProps: FullProps, prevState: State): void {
    if (
      JSON.stringify(this.state.visibleQuestions) !==
      JSON.stringify(prevState.visibleQuestions)
    ) {
      if (this.isOverflowActive(this.surveyScreenRef.current)) {
        this.setState({
          overflowActive: true,
        });
      } else {
        this.setState({
          overflowActive: false,
        });
      }
    }
  }
  isOverflowActive = (event: any): any => {
    return (
      window.innerHeight < event.scrollHeight ||
      window.innerHeight < event.scrollWidth
    );
  };
  // getMessage = (): string => {
  //   if (this.props?.errorResponseDTO?.message) {
  //     return this.props?.errorResponseDTO?.message;
  //   } else if (this.props?.success) {
  //     return translations.APPRECIATE_FEEDBACK;
  //   } else if (this.props.error) {
  //     return translations.SOMETHING_WENT_WRONG;
  //   }
  //   return '';
  // };
  // getHeaderTitle = (): string => {
  //   if (this.props?.errorResponseDTO?.message || this.props.error) {
  //     return translations.OOPS;
  //   } else if (this.props?.success) {
  //     return translations.THANK_YOU;
  //   } else if (this.props.questions.length > 0) {
  //     return translations.WE_WANT_TO_HEAR;
  //   }
  //   return '';
  // };
  render(): React.ReactNode {
    return (
      <div data-testid="survey-screen" ref={this.surveyScreenRef}>
        <div
          className={`survey_bg ${
            this.state.currentScreenAnswers >= this.state.validateFor &&
            this.state.overflowActive
              ? 'pb100'
              : ''
          }`}
        >
          <div className="survey_wrapper">
            {this.state.isSubmitted && this.state.visibleQuestions.length > 0 && (
              <Header
                // message={this.getMessage()}
                title={translations.THANK_YOU}
                description={this.state.headerDesc}
              />
            )}
            <div>
              <div className="survey_form">
                {this.state.visibleQuestions.length > 0 && (
                  <div className="queAnsSection">
                    <QuestionAnswer
                      ref={this.queComponentRef}
                      questions={this.state.visibleQuestions}
                      givenAnswers={this.receivedAnswers}
                      // validateFor={this.state.validateFor}
                      selectedAnswer={this.props.backgroundSubmit}
                      noOfAnswersSelected={(answerCount) => {
                        this.setState({
                          currentScreenAnswers: answerCount,
                          submitEnable: answerCount === this.state.validateFor,
                        });
                      }}
                    />
                  </div>
                )}
              </div>
            </div>

            {(this.props.success || this.props?.errorResponseDTO?.message) && (
              <div className="center_content_pg">
                <div className="main_hd">{translations.THANK_YOU}</div>
                {this.props.success && (
                  <p className="sub_info">{translations.APPRECIATE_FEEDBACK}</p>
                )}
                {this.props?.errorResponseDTO?.message && (
                  <p className="sub_info">
                    {translations.ALREADY_RECEIVED_FEEDBACK}
                  </p>
                )}
              </div>
            )}
            {this.props.errorCode === 400 && (
              <div className="center_content_pg">
                <img
                  src={TIME_EXPIRED_IMG}
                  title=""
                  alt="expired"
                  className="mb15"
                />
                <div className="main_hd">{translations.LINK_EXPIRED}</div>
                <p className="sub_info">{translations.PAGE_NO_AVAILABLE}</p>
              </div>
            )}
          </div>
        </div>
        {this.state.visibleQuestions.length > 0 && (
          <div
            className={`row ${
              this.state.currentScreenAnswers >= this.state.validateFor &&
              this.state.overflowActive
                ? 'mlm20 mrm20 bottom_btn_fix '
                : 'ml0 mr0'
            }`}
          >
            {this.state.currentScreenAnswers >= this.state.validateFor && (
              <button
                onClick={() => this.queComponentRef.current.submitData()}
                className="btn submit_btn black ui-link"
              >
                {translations.SUBMIT}
              </button>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default SurveyScreen;
