/* NOTE: un-comment below line and it will show how unnecessary re-render of component in console log*/

import './wdyr';
import React from 'react';
import { Route, HashRouter, Redirect } from 'react-router-dom';
import Survey from './containers/surveyContainer';
import Loader from './components/loader';
import { connect } from 'react-redux';
import { ConfigInitialStateTypes } from './types/reducerTypes';
import { Dispatch } from 'redux';
import { routes } from './constants/utilConstants';
import { AlertModal } from './utils/AlertModal';

interface Props {
  config: ConfigInitialStateTypes;
}

const App: React.FC<Props> = (props) => {
  const { config } = props;
  return (
    <div>
      {config.isLoading && <Loader show={config.isLoading} />}
      <AlertModal show={config.showError} {...config.alertData} />
      <HashRouter>
        <Route path={routes.SURVEY} exact component={Survey} />
        {process.env.NODE_ENV === 'development' && (
          <Route exact path="/">
            <Redirect to={routes.SURVEY} />
          </Route>
        )}
      </HashRouter>
    </div>
  );
};
const mapStateToProps = (props: Props): Props => {
  return {
    config: props.config,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): object => {
  return { dispatch };
};
export default connect(mapStateToProps, mapDispatchToProps)(App);
