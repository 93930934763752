export const translations = {
  REQUEST_RESET_LINK: `Request Reset Link`,
  SOMETHING_WENT_WRONG: `Something Went wrong...`,
  NPS_SURVEY_SCREEN: `NPS Survey Screen`,
  GENERAL_ERROR_MSG: `Oops! Something went wrong. Try again later.`,
  WHY_YOU_GAVE_THIS_RATE: `Please let us know why you gave us this rating:`,
  SUBMIT: `Submit`,
  APPRECIATE_FEEDBACK: `We appreciate your feedback.`,
  OOPS_API_ERROR: `Something went wrong. Please try submitting your feedback again.`,
  OOPS: `Oops!`,
  THANK_YOU: `Thank You!`,
  WE_WANT_TO_HEAR: `We would love feedback on your recent roadside assistance experience.`,
  LINK_EXPIRED: `Link Expired`,
  ALREADY_RECEIVED_FEEDBACK: `It looks like you’ve already 
  provided feedback.`,
  PAGE_NO_AVAILABLE: `This page is no longer available.`,
  NPS_DESC: (caseid: number, date: string) => `Service #${caseid} on ${date}`,
  OK: `OK`,
};
