import { SurveyInitialStateTypes } from '../../types/reducerTypes';
import {
  GET_SURVEY_QUESTIONS_SUCCESS,
  SEND_SURVEY_ANSWERS_SUCCESS,
  // SEND_SURVEY_ANSWERS_ERROR,
} from './surveyActionTypes';
// const testQuestions = [
//   {
//     questionId: 3,
//     questionType: 'radio',
//     question:
//       '3 On the scale of zero-to-ten, how likely would you recommend this service to a friend or family member?',
//     answers: [
//       {
//         tagCode: '0',
//         tagValue: '0 - Not At All Likely',
//       },
//       {
//         tagCode: '1',
//         tagValue: '1',
//       },
//       {
//         tagCode: '2',
//         tagValue: '2',
//       },
//       {
//         tagCode: '3',
//         tagValue: '3',
//       },
//       {
//         tagCode: '4',
//         tagValue: '4',
//       },
//       {
//         tagCode: '5',
//         tagValue: '5',
//       },
//       {
//         tagCode: '6',
//         tagValue: '6',
//       },
//       {
//         tagCode: '7',
//         tagValue: '7',
//       },
//       {
//         tagCode: '8',
//         tagValue: '8',
//       },
//       {
//         tagCode: '9',
//         tagValue: '9',
//       },
//       {
//         tagCode: '10',
//         tagValue: '10 - Extremely Likely',
//       },
//     ],
//   },
//   {
//     questionId: 4,
//     questionType: 'radio',
//     question:
//       '4 On the scale of zero-to-ten, how likely would you recommend this service to a friend or family member?',
//     answers: [
//       {
//         tagCode: '0',
//         tagValue: '0 - Not At All Likely',
//       },
//       {
//         tagCode: '1',
//         tagValue: '1',
//       },
//       {
//         tagCode: '2',
//         tagValue: '2',
//       },
//       {
//         tagCode: '3',
//         tagValue: '3',
//       },
//       {
//         tagCode: '4',
//         tagValue: '4',
//       },
//       {
//         tagCode: '5',
//         tagValue: '5',
//       },
//       {
//         tagCode: '6',
//         tagValue: '6',
//       },
//       {
//         tagCode: '7',
//         tagValue: '7',
//       },
//       {
//         tagCode: '8',
//         tagValue: '8',
//       },
//       {
//         tagCode: '9',
//         tagValue: '9',
//       },
//       {
//         tagCode: '10',
//         tagValue: '10 - Extremely Likely',
//       },
//     ],
//   },
// ];
export const surveyData: SurveyInitialStateTypes = {
  caseId: 0,
  caseCreatedOn: '',
  questions: [],
  errorResponseDTO: {
    code: '',
    message: '',
    serviceName: '',
  },
  success: false,
};

export const surveyReducer = (state = surveyData, action: any): object => {
  switch (action.type) {
    case GET_SURVEY_QUESTIONS_SUCCESS:
      return {
        ...state,
        ...action.response,
        // questions: [...action.response.questions, ...testQuestions],
      };
    case SEND_SURVEY_ANSWERS_SUCCESS:
      return {
        ...state,
        ...action.response,
        questions: [],
        success: true,
      };
    // case SEND_SURVEY_ANSWERS_ERROR:
    //   return {
    //     ...state,
    //     ...action.response,
    //     questions: [],
    //     error: true,
    //   };
    default:
      return state;
  }
};
