import React from 'react';
import { AlertData } from '../types/reducerTypes';
import { HIDE_ALERT, SHOW_ALERT } from '../saga/config/configActionTypes';
import { getStore } from '../store/store';

interface Props {
  show: boolean;
}
type Callback = (param?: any) => any;
type FullProps = AlertData & Props;
export const AlertModal: React.FC<FullProps> = ({
  show = false,
  header,
  body,
  leftText,
  rightText,
  leftCallback,
  rightCallback,
}) => {
  return (
    <div className={`pg dialog ${show ? 'show' : 'hide'}`}>
      <div className="dialogBox pb0">
        {header && <h2 className="title">{header}</h2>}
        <p className="errorBody">{body}</p>
        <div className="row">
          <div className="control">
            <div className="d-flex">
              {leftText && (
                <div className="col pl-0 pr-0 bRight">
                  <span className="lnk" onClick={leftCallback}>
                    {leftText}
                  </span>
                </div>
              )}
              {rightText && (
                <div className="col pr-0 pl5">
                  <span className="lnk strong" onClick={rightCallback}>
                    {rightText}
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="overlay" />
    </div>
  );
};
export const hideAlert = function (store: any): void {
  store.dispatch({
    type: HIDE_ALERT,
  });
};
export const showAlertModal = (
  body: string,
  header?: string,
  leftText?: string,
  leftCallback?: Callback,
  rightText?: string,
  rightCallback?: Callback
): void => {
  const store = getStore();
  if (leftText && leftText.trim() !== '' && !leftCallback) {
    leftCallback = () => hideAlert(store);
  }
  if (rightText && rightText.trim() !== '' && !rightCallback) {
    rightCallback = () => hideAlert(store);
  }
  //   showHideAlert(alertData);
  store.dispatch({
    type: SHOW_ALERT,
    alertData: {
      header,
      body,
      leftText,
      leftCallback,
      rightText,
      rightCallback,
    },
  });
};
