import { rootReducer } from '../reducers/combineReducer';
import { InitialAppStateTypes } from '../types/reducerTypes';
import { applyMiddleware, createStore, Store } from 'redux';
import { createLogger } from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import rootSaga from '../saga/saga';

let store: Store<InitialAppStateTypes>;
export default function configureStore(
  initialState: any,
  isActual = true
): Store<InitialAppStateTypes> {
  const logger = createLogger();
  const sagaMiddleware = createSagaMiddleware();
  let middlewares;
  if (isActual) {
    middlewares = applyMiddleware(sagaMiddleware, logger); // Create Store
  } else {
    middlewares = applyMiddleware(sagaMiddleware); // Create Store
  }
  store = createStore(rootReducer, initialState, middlewares);
  if (isActual) {
    sagaMiddleware.run(rootSaga);
  }
  return store;
}
export function getStore(): Store<InitialAppStateTypes> {
  return store;
}
