// import config from '../utils/config';
export const HOSTURL = process.env.REACT_APP_API_HOST;
export const HTTP_POST = `POST`;
export const HTTP_PUT = `PUT`;
export const HTTP_GET = `GET`;
export const POST_SURVEY_DATA_API = `${HOSTURL}/csat`;
export const GET_SURVEY_QUESTIONS_API = (
  partnerCode: string,
  entityType: string,
  entityId: string
): string =>
  `${HOSTURL}/csat?partnerCode=${partnerCode}&entityId=${entityId}&entityType=${entityType}`;
export const GET_AUTH_TOKEN_API = (token: string): string =>
  `${HOSTURL}/csat/verify/token/${token}`;
