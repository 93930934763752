export const SHOW_LOADING = 'SHOW_LOADING';
export const HIDE_LOADING = 'HIDE_LOADING';
export const ERROR_OCCUR = 'ERROR_OCCUR';
export const ERROR_REMOVE = 'ERROR_REMOVE';
export const AUTH_EXPIRE = 'AUTH_EXPIRE';

// Keys Actions
export const KEYS_REQUEST = 'KEYS_REQUEST';
export const KEYS_SUCCESS = 'KEYS_SUCCESS';
export const KEYS_FAILURE = 'KEYS_FAILURE';
export const SHOW_ALERT = 'SHOW_ALERT';
export const HIDE_ALERT = 'HIDE_ALERT';
