import React from 'react';
import { connect } from 'react-redux';
import {
  AuthInitialStateTypes,
  ConfigInitialStateTypes,
  SurveyInitialStateTypes,
} from '../types/reducerTypes';
import { withRouter } from 'react-router';
import translation from '../utils/language';
import SurveyScreen from '../components/survey/survey';
import { Dispatch } from 'redux';
import {
  GET_SURVEY_QUESTIONS,
  SEND_SURVEY_ANSWERS,
} from '../saga/survey/surveyActionTypes';
import { GET_AUTH_TOKEN } from '../saga/auth/authActionTypes';
// import { getAuthToken } from '../saga/auth/actions';

interface CaseData {
  partnerCode: string;
  entityId: number;
  entityType: string;
}
interface Props {
  authentication: AuthInitialStateTypes;
  config: ConfigInitialStateTypes;
  surveyData: SurveyInitialStateTypes;
  location: any;
  getSurveyQuestions: (param: CaseData) => void;
  getAuthToken: (params: string) => void;
  submitSurvey: (params: any) => void;
}
interface State {
  commonParam: CaseData;
}

class Survey extends React.Component<Props, State> {
  screenName: string;
  constructor(props: Props) {
    super(props);
    this.screenName = translation.NPS_SURVEY_SCREEN;
    this.state = {
      commonParam: {
        partnerCode: '',
        entityId: 0,
        entityType: '',
      },
    };
    const token = new URLSearchParams(this.props.location.search).get('token');
    if (token) {
      this.props.getAuthToken(token);
    }
  }
  componentDidUpdate(prevProps: Props): void {
    if (
      this.props.authentication.internalAccessToken !==
      prevProps.authentication.internalAccessToken
    ) {
      this.setState(
        {
          commonParam: {
            partnerCode: this.props.authentication.partnerCode,
            entityId: this.props.authentication.caseId,
            entityType: 'case',
          },
        },
        () => {
          this.props.getSurveyQuestions(this.state.commonParam);
        }
      );
    }
  }

  render(): React.ReactNode {
    return (
      <>
        {(this.props.surveyData && this.props.surveyData.caseId) ||
        this.props.config?.error ? (
          <SurveyScreen
            {...this.props.surveyData}
            error={this.props.config.error}
            errorData={this.props.config.errorData}
            errorCode={this.props.config.apiStatusCode}
            sendSurvey={(data) =>
              this.props.submitSurvey({
                details: [...data],
                ...this.state.commonParam,
              })
            }
            backgroundSubmit={(data) =>
              this.props.submitSurvey({
                details: [...data],
                ...this.state.commonParam,
                isBackgroundSubmit: true,
              })
            }
            partnerCode={this.props.authentication.partnerCode}
          />
        ) : (
          ''
        )}
      </>
    );
  }
}
const mapStateToProps = (props: Props): object => {
  return {
    authentication: props.authentication,
    config: props.config,
    surveyData: props.surveyData,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): object => {
  return {
    getAuthToken: (token: string) => {
      dispatch({ type: GET_AUTH_TOKEN, token });
    },
    getSurveyQuestions: (params: CaseData) => {
      dispatch({ type: GET_SURVEY_QUESTIONS, data: params });
    },
    submitSurvey: (data: any) => {
      dispatch({
        type: SEND_SURVEY_ANSWERS,
        data,
      });
    },
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Survey) as any
);
