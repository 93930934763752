import { AuthInitialStateTypes } from '../../types/reducerTypes';
import { GET_AUTH_TOKEN_SUCCESS } from './authActionTypes';

export const authData: AuthInitialStateTypes = {
  partnerCode: '',
  transactionCode: '',
  internalAccessToken: '',
  caseId: 0,
};

export const authReducer = (state = authData, action: any): object => {
  switch (action.type) {
    case GET_AUTH_TOKEN_SUCCESS:
      return {
        ...state,
        ...action.response,
      };
    default:
      return state;
  }
};
