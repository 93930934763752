import * as React from 'react';
interface Props {
  title: string;
  description: string;
  message?: string;
}

const Header: React.FC<Props> = (props) => {
  return (
    <div>
      <header>
        <div className="container">
          <h2 className={`title ${props.message ? `msg_title` : ``}`}>
            {props.title}
          </h2>
          {props?.message !== '' && (
            <div className="survey_hd gap msgSection">{props?.message}</div>
          )}
          {props?.description && (
            <div className="info">{props.description}</div>
          )}
        </div>
      </header>
    </div>
  );
};
export default Header;
