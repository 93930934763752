import * as React from 'react';
import { SPINNER_IMG } from '../utils/images';

interface Props {
  show: boolean;
}

export const Loader: React.FC<Props> = (props) => {
  const { show } = props;
  return (
    <div className={`loader ${show ? `show` : `hide`}`}>
      <img src={SPINNER_IMG} title="" alt="" />
    </div>
  );
};

export default Loader;
