import { put, takeLatest } from 'redux-saga/effects';
import {
  HIDE_LOADING,
  SHOW_LOADING,
  ERROR_REMOVE,
  ERROR_OCCUR,
} from '../config/configActionTypes';
import { GET_AUTH_TOKEN, GET_AUTH_TOKEN_SUCCESS } from './authActionTypes';
import { GET_AUTH_TOKEN_API, HTTP_GET } from '../../constants/API';
import API from '../../utils/API';
import translations from '../../utils/language';

export function* getAuthToken({ token }: any): any {
  try {
    // console.log('token=====>', token);
    yield put({ type: SHOW_LOADING });
    // const response = { data: true };
    const response = yield API(
      HTTP_GET,
      GET_AUTH_TOKEN_API(token),
      false,
      false
    );
    yield put({ type: HIDE_LOADING });
    if (response?.data) {
      yield put({
        type: GET_AUTH_TOKEN_SUCCESS,
        response: response.data,
      });
      yield put({ type: ERROR_REMOVE });
    }
  } catch (error: any) {
    yield put({ type: HIDE_LOADING });
    if (error?.response?.data) {
      yield put({
        type: ERROR_OCCUR,
        response: error.response.data,
        statusCode: error.response.status,
      });
    } else {
      yield put({
        type: ERROR_OCCUR,
        response: { error: translations.GENERAL_ERROR_MSG },
        statusCode: error.response.status,
      });
    }
  }
}

function* authWatcher(): any {
  yield takeLatest(GET_AUTH_TOKEN, getAuthToken);
}

export default authWatcher;
