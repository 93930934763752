const translations = {
  translation: (function () {
    if (process.env.REACT_APP_LANGUAGE === 'EN') {
      return require('../constants/languageEn').translations;
    } else {
      return require('../constants/languageFr').translations;
    }
  })(),
};

// export const paramReplace = (text: string, params: any): string => {
//   // const re = new RegExp('{{' + Object.keys(params).join('}}|{{') + '}}', 'gi');
//   // console.log('regex', re);
//   return text.replace(/{{\w+}}/g, function (all) {
//     return params[all.substring(2, all.length - 2)] || all;
//   });
// };

export default translations.translation;
