import { authReducer } from '../saga/auth/authReducers';
import { configReducer } from '../saga/config/configReducer';
import { surveyReducer } from '../saga/survey/surveyReducers';
import { combineReducers } from 'redux';
// import { CLEAR_STORE } from '../saga/login/loginActionTypes';

export const appReducer = combineReducers({
  authentication: authReducer,
  config: configReducer,
  surveyData: surveyReducer,
});

export const rootReducer = (state: any, action: any): any => {
  // if (action.type === CLEAR_STORE) {
  //   state = undefined;
  // }
  return appReducer(state, action);
};
