import { put, takeLatest /*delay*/ } from 'redux-saga/effects';
import {
  HIDE_LOADING,
  SHOW_LOADING,
  ERROR_REMOVE,
  ERROR_OCCUR,
} from '../config/configActionTypes';
import {
  GET_SURVEY_QUESTIONS,
  GET_SURVEY_QUESTIONS_SUCCESS,
  SEND_SURVEY_ANSWERS,
  SEND_SURVEY_ANSWERS_SUCCESS,
  // SEND_SURVEY_ANSWERS_ERROR,
} from './surveyActionTypes';
import {
  GET_SURVEY_QUESTIONS_API,
  HTTP_GET,
  HTTP_POST,
  POST_SURVEY_DATA_API,
} from '../../constants/API';
import API from '../../utils/API';
import translations from '../../utils/language';

export function* getSurveyQuestions({ data }: any): any {
  try {
    yield put({ type: SHOW_LOADING });
    const response = yield API(
      HTTP_GET,
      GET_SURVEY_QUESTIONS_API(
        data.partnerCode,
        data.entityType,
        data.entityId
      ),
      false,
      true
    );
    // const response = { data: {} };
    yield put({ type: HIDE_LOADING });
    if (response?.data) {
      yield put({
        type: GET_SURVEY_QUESTIONS_SUCCESS,
        response: response.data,
      });
      yield put({ type: ERROR_REMOVE });
    }
  } catch (error: any) {
    yield put({ type: HIDE_LOADING });
    if (error?.response?.data) {
      yield put({ type: ERROR_OCCUR, response: error.response.data });
    } else {
      yield put({
        type: ERROR_OCCUR,
        response: { error: translations.GENERAL_ERROR_MSG },
      });
    }
  }
}

export function* sendSurveyAnswers({ data }: any): any {
  if (data?.isBackgroundSubmit) {
    yield API(HTTP_POST, POST_SURVEY_DATA_API, data, true);
  } else {
    try {
      yield put({ type: SHOW_LOADING });
      const response = yield API(HTTP_POST, POST_SURVEY_DATA_API, data, true);
      // yield delay(2000);
      // const response = { data: { data } };
      yield put({ type: HIDE_LOADING });
      if (response?.data) {
        yield put({
          type: SEND_SURVEY_ANSWERS_SUCCESS,
          response: response.data,
        });
        yield put({ type: ERROR_REMOVE });
      }
    } catch (error: any) {
      yield put({ type: HIDE_LOADING });
      if (error?.response?.data) {
        yield put({
          type: ERROR_OCCUR,
          response: error.response.data,
        });
      } else {
        yield put({
          type: ERROR_OCCUR,
          response: { error: translations.GENERAL_ERROR_MSG },
        });
      }
    }
  }
}

function* surveyWatcher(): any {
  yield takeLatest(GET_SURVEY_QUESTIONS, getSurveyQuestions);
  yield takeLatest(SEND_SURVEY_ANSWERS, sendSurveyAnswers);
}

export default surveyWatcher;
